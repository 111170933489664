<template>
  <div class="main-block claim-card">
    <div class="claim-card__header">
      <img :src="require(`@/assets/img/${item.token}-token.svg`)" :alt="item.token">
      {{ contract.toUpperCase() }}
    </div>
    <div class="claim-card__icons">
      <div class="claim-card__icon">
        <img :src="require(`@/assets/img/bridge/${item.from}-logo.svg`)" :alt="item.from">
      </div>
      <div class="claim-card__arrow">
        <img src="@/assets/img/bridge/arrow.svg" alt="arrow">
      </div>
      <div class="claim-card__icon">
        <img :src="require(`@/assets/img/bridge/${item.to}-logo.svg`)" :alt="item.to">
      </div>
    </div>

    <div class="claim-card__available"> {{ available || notAvailable }} </div>
    <div class="claim-card__token" v-if="contract"> {{ contract.toUpperCase() }} </div>
    <v-btn
      rounded
      block
      v-if="notAvailable === 0 || available > 0"
      :disabled="available === 0"
      @click="claim"
      class="bridge-button btn"
      :loading="loading"
    >
      Claim
    </v-btn>
    <v-btn
      rounded
      block
      v-else
      class="bridge-button btn"
      :loading="true"
    >
      Claim
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueCookie from 'vue-cookie';

export default {
  name: 'ClaimCard',
  props: {
    item: Object,
  },
  data() {
    return {
      loading: false,
      ids: [],
    };
  },

  methods: {
    saveOrder(id) {
      try {
        const data = VueCookie.get('claimed') || '[]';
        this.ids = JSON.parse(data);
        this.ids.push(id);
        VueCookie.set('claimed', JSON.stringify(this.ids));
      } catch (e) {
        console.log(e);
      }
    },

    async updateBalance() {
      try {
        await Promise.all([
          this.$store.dispatch('Milk/update', {}),
          this.$store.dispatch('Shake/update', {}),
        ]);
      } catch (e) {
        console.error(e?.message ?? e);
      }
    },

    async claim() {
      try {
        this.loading = true;
        const data = await this.$store.dispatch('Bridge/getClaimData', {
          orderId: this.order.orderId,
          unstakedTokenAddress: this.tokenAddress,
        });
        const payOutObject = {
          orderId: this.order.orderId,
          amount: this.$web3.utils.toWei(this.order.amount.toString()),
          tokenAddress: this.tokenAddress,
          msgForSign: data?.data?.message,
          signature: data?.data?.signature,
        };
        await this.$store.dispatch('Bridge/payOut', payOutObject);
        this.saveOrder(payOutObject.orderId);
        this.$notify({ group: 'info', title: 'Successful claim!' });
        await this.updateBalance();
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
        let msg = e?.response?.data?.message ?? e?.message;
        if (msg.includes('Transaction has been reverted by the EVM')) {
          msg = 'Transaction has been reverted by the EVM';
        }
        if (msg) this.$notify({ group: 'info', title: msg });
      }
    },
  },

  computed: {
    ...mapGetters({
      orders: 'Bridge/activeOrders',
      processingOrders: 'Bridge/processingOrders',
      networks: 'Bridge/networks',
      chainId: 'User/chainId',
      testnets: 'Bridge/testnets',
      chains: 'Bridge/chains',
    }),

    tokenAddress() {
      return this.$contracts.getAddress(this.contract);
    },

    order() {
      return this.orders.find((order) => (order.ticker === this.contract.toUpperCase()
        && order.networkTo === this.toChain && order.networkFrom === this.fromChain));
    },

    processingOrder() {
      return this.processingOrders.find((order) => (order.ticker === this.contract.toUpperCase()
        && order.networkTo === this.toChain && order.networkFrom === this.fromChain));
    },

    notAvailable() {
      return Number(Number(this.processingOrder?.amount).toFixed(6)) || 0;
    },
    available() {
      if (this.ids.includes(this.order?.orderId)) return 0;
      return Number(Number(this.order?.amount).toFixed(6)) || 0;
    },

    contract() {
      return this.item.token === 'milk' ? 'milk2' : this.item.token;
    },

    currentChain() {
      return this.chains[this.chainId];
    },

    toChain() {
      if (this.testnets.includes(this.currentChain)) {
        return this.networks.testnet[this.item.to];
      }
      return this.networks.mainnet[this.item.to];
    },

    fromChain() {
      if (this.testnets.includes(this.currentChain)) {
        return this.networks.testnet[this.item.from];
      }
      return this.networks.mainnet[this.item.from];
    },
  },

  mounted() {
    const data = VueCookie.get('claimed') || '[]';
    this.ids = JSON.parse(data);
  },
};
</script>

<style scoped lang="scss">
.claim-card{
  padding: 5rem 2rem 2rem;
  text-align: center;
  &__header{
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 155%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.03em;
    margin-bottom: 2.6rem;
    img{
      width: 3.2rem;
      margin-right: 1.2rem;
    }
  }
  &__arrow{
    padding: 0 1rem;
    img{
      height: 3.5rem;
    }
  }
  &__title{
    font-weight: bold;
    font-size: 2.3rem;
    line-height: 135%;
    letter-spacing: 0.03em;
    color: #283287;
    margin-bottom: 3.2rem;
  }
  &__token{
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 155%;
    text-align: center;
    letter-spacing: 0.03em;
    margin-bottom: 6rem;
  }
  &__available{
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 155%;
    text-align: center;
    color: #283287;
  }

  &__icons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 6rem;
  }

  &__icon{
    height: 7rem;
    background: #F8FCFD;
    border-radius: 4rem;
    box-shadow: 10px 10px 20px rgba(222, 222, 222, 0.25), 2px 2px 20px rgba(212, 202, 205, 0.2),
    -10px -10px 15px #FFFFFF, 10px 10px 20px rgba(181, 155, 155, 0.25),
    inset 2px 2px 6px #FFFFFF, inset -1px -1px 2px rgba(181, 155, 155, 0.2);
    img{
      height: 100%;
      padding: 1.1rem;
    }
  }

  body.avalanche &{
    &__title, &__available{
      color: #151515;
    }
  }
  body.binance &{
    &__title, &__available{
      color: white;
    }
    &__icon{
      background: #2F2F2F;
      box-shadow: 6px 6px 8px #16161B, -6px -6px 12px #333342, inset 2px 2px 5px #47475A,
      inset -1px -1px 4px rgba(0, 0, 0, 0.69), inset -3px -3px 12px rgba(4, 4, 10, 0.6),
      inset 2px 2px 2px rgba(255, 255, 255, 0.3);
    }
  }
}
</style>
