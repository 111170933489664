<template>
  <div class="beta">
    <img class="beta__eth" src="@/assets/img/bridge/beta.svg" alt="beta">
    <img class="beta__bsc" src="@/assets/img/bridge/beta-bsc.svg" alt="beta">
    <img class="beta__avax" src="@/assets/img/bridge/beta-avax.svg" alt="beta">
    <span>Temporarily out of service</span>
  </div>
</template>

<script>
export default {
  name: 'OutOfService',
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.beta{
  position: absolute;
  bottom: 500px;
  top: 80px;
  width: 95%;
  right: 2.5%;
  z-index: 9999999;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.9), -1px -1px 5px rgba(182, 169, 173, 0.3),
    inset -2px -2px 2px rgba(182, 169, 173, 0.2), inset 1px 1px 4px rgba(182, 169, 173, 0.2),
    inset -2px -2px 4px rgba(255, 255, 255, 0.9), inset 1px 1px 2px rgba(182, 169, 173, 0.2);
  border-radius: 3rem;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000E89;
  padding: 2.8rem;
  img{
    border-radius: 3rem;
    margin-right: 2rem;
    width: 3.5rem;
    box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.35),
    inset -1px -1px 2px rgba(255, 161, 145, 0.06);
    filter: drop-shadow(-3px 3px 6px rgba(255, 161, 145, 0.25))
      drop-shadow(3px -3px 6px rgba(255, 161, 145, 0.19))
      drop-shadow(-3px -7px 10px #FFFFFF)
      drop-shadow(3px 8px 6px rgba(255, 161, 145, 0.36));
  }
  &__bsc, &__avax{
    display: none;
  }
  body.binance &{
    background: linear-gradient(180deg, #1A1A20 0%, #181822 100%);
    box-shadow: 5px 5px 15px #26262f, -2px -2px 20px #23232d,
      inset -2px -2px 10px rgb(22 22 26 / 60%), inset 2px 2px 20px rgb(23 24 33 / 20%),
      inset -2px -2px 6px #171724, inset -4px -4px 20px rgb(0 0 0 / 6%);
    color: white;
    img{
      box-shadow: none;
      filter: none;
    }
    &__eth, &__avax{
      display: none;
    }
    &__bsc{
      display: block;
    }
  }
  body.avalanche &{
    color: #FC7172;
    background: #EFEFEF;
    box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.9),
      -1px -1px 5px rgba(182, 169, 173, 0.3), inset -2px -2px 2px rgba(182, 169, 173, 0.2),
      inset 1px 1px 4px rgba(182, 169, 173, 0.2), inset -2px -2px 4px rgba(255, 255, 255, 0.9),
      inset 1px 1px 2px rgba(182, 169, 173, 0.2);
    img{
      box-shadow: none;
      filter: none;
    }
    &__eth, &__bsc{
      display: none;
    }
    &__avax{
      display: block;
    }
  }
}
</style>
