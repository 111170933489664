const swapData = {
  ethereum: [
    {
      title: 'Ethereum to BSC',
      items: [
        {
          token: 'milk',
          from: 'eth',
          to: 'bsc',
          title: 'MILK2 to BSC',
        },
        {
          token: 'shake',
          from: 'eth',
          to: 'bsc',
          title: 'SHAKE to BSC',
        },
      ],
    },
    {
      title: 'Ethereum to Avalanche',
      items: [
        {
          token: 'milk',
          from: 'eth',
          to: 'avax',
          title: 'MILK2 to Avalanche',
        },
        {
          token: 'shake',
          from: 'eth',
          to: 'avax',
          title: 'SHAKE to Avalanche',
        },
      ],
    },
  ],
  binance: [
    {
      title: 'BSC to Ethereum',
      items: [
        {
          token: 'milk',
          from: 'bsc',
          to: 'eth',
          title: 'MILK2 to ETH',
        },
        {
          token: 'shake',
          from: 'bsc',
          to: 'eth',
          title: 'SHAKE to ETH',
        },
      ],
    },
    {
      title: 'BSC to Avalanche',
      items: [
        {
          token: 'milk',
          from: 'bsc',
          to: 'avax',
          title: 'MILK2 to Avalanche',
        },
        {
          token: 'shake',
          from: 'bsc',
          to: 'avax',
          title: 'SHAKE to Avalanche',
        },
      ],
    },
  ],
  avalanche: [
    {
      title: 'Avalanche to Ethereum',
      items: [
        {
          token: 'milk',
          from: 'avax',
          to: 'eth',
          title: 'MILK2 to ETH',
        },
        {
          token: 'shake',
          from: 'avax',
          to: 'eth',
          title: 'SHAKE to ETH',
        },
      ],
    },
    {
      title: 'Avalanche to BSC',
      items: [
        {
          token: 'milk',
          from: 'avax',
          to: 'bsc',
          title: 'MILK2 to BSC',
        },
        {
          token: 'shake',
          from: 'avax',
          to: 'bsc',
          title: 'SHAKE to BSC',
        },
      ],
    },
  ],
};

const claimData = {
  ethereum: [
    {
      title: 'Claim from BSC',
      items: [
        {
          token: 'milk',
          from: 'bsc',
          to: 'eth',
        },
        {
          token: 'shake',
          from: 'bsc',
          to: 'eth',
        },
      ],
    },
    {
      title: 'Claim from Avalanche',
      items: [
        {
          token: 'milk',
          from: 'avax',
          to: 'eth',
        },
        {
          token: 'shake',
          from: 'avax',
          to: 'eth',
        },
      ],
    },
  ],
  binance: [
    {
      title: 'Claim from Ethereum',
      items: [
        {
          token: 'milk',
          from: 'eth',
          to: 'bsc',
        },
        {
          token: 'shake',
          from: 'eth',
          to: 'bsc',
        },
      ],
    },
    {
      title: 'Claim from Avalanche',
      items: [
        {
          token: 'milk',
          from: 'avax',
          to: 'bsc',
        },
        {
          token: 'shake',
          from: 'avax',
          to: 'bsc',
        },
      ],
    },
  ],
  avalanche: [
    {
      title: 'Claim from Ethereum',
      items: [
        {
          token: 'milk',
          from: 'eth',
          to: 'avax',
        },
        {
          token: 'shake',
          from: 'eth',
          to: 'avax',
        },
      ],
    },
    {
      title: 'Claim from BSC',
      items: [
        {
          token: 'milk',
          from: 'bsc',
          to: 'avax',
        },
        {
          token: 'shake',
          from: 'bsc',
          to: 'avax',
        },
      ],
    },
  ],
};

export {
  swapData,
  claimData,
};
