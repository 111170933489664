<template>
  <v-col class="main-wrap main-wrap--narrow bridge-claim">
    <OutOfService />
    <div class="page-content">
      <v-row class="page-content" align="stretch">
        <v-col class="col-12 col-md-6" v-for="(network, i) of swapData" :key="i">
          <div class="bridge-claim__network main-block">
            <div class="bridge-claim__title">
              {{ network.title }}
            </div>
            <v-row>
              <v-col v-for="(item, j) of network.items" :key="`item-${j}`">
                <ClaimCard :item="item"/>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="to-claim">
      To check on your swap transactions, please proceed to the
      <router-link to="/new-bridge/explorer">explorer page</router-link>.
    </div>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import ClaimCard from '@/components/Bridge/ClaimCard';
import { claimData } from '@/constants/shadowBridge';
import OutOfService from '@/components/Bridge/OutOfService';

export default {
  name: 'claim',
  components: { OutOfService, ClaimCard },

  data() {
    return {
      networks: claimData,
      interval: null,
    };
  },

  computed: {
    ...mapGetters({
      orders: 'Bridge/activeOrders',
      userAddress: 'User/ethAddress',
      chainId: 'User/chainId',
    }),

    chainName() {
      return this.$chain.getName();
    },

    swapData() {
      return this.networks[this.chainName];
    },
  },

  watch: {
    async userAddress() {
      await this.updateData();
    },
    async chainId() {
      await this.updateData();
    },
  },

  methods: {
    async updateData() {
      await Promise.all([
        this.$store.dispatch('Bridge/swapAllowance'),
        this.$store.dispatch('Milk/update', {}),
        this.$store.dispatch('Shake/update', {}),
      ]);
    },
  },

  async mounted() {
    await this.$store.dispatch('Bridge/getActiveOrders');
    this.interval = setInterval(async () => {
      await this.$store.dispatch('Bridge/getActiveOrders');
    }, 10000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss">
.to-claim{
  text-align: center;
  font-size: 2rem;
}
.bridge-claim{
  width: 100%;
  max-width: 151rem;
  &__network{
    padding: 4rem 3rem 3rem;
  }
  &__title{
    font-weight: bold;
    font-size: 3.4rem;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.03em;
    color: #283287;
    margin-bottom: 3.2rem;
  }

  body.binance &{
    &__title{
      color: white;
    }
    &__amount{
      color: white;
    }
  }

  body.avalanche &{
    &__amount, &__title{
      color: #151515;
    }
  }
}
</style>
